<template>
  <div id="mian">
    <div class="noAccess">
      <div class="link">无页面权限，请联系超级管理员开通权限</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  created() {

  },
  methods: {

  },
  beforeDestroy() {

  },
};
</script>
<style scoped>
.noAccess{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  line-height: 40px;
  background: #f0faff;
  padding: 0px 20px;
  margin-bottom: 20px;
  color: var(--themeColor);
  font-size: 14px;
}
</style>
